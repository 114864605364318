console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import  './skip-link-focus-fix';
import  './smoothscroll';
import  './navigation';
import  './scrollyclasses';
import  './viewport-height';
import  './fw-video';
import  './sliders';
import  './accordions';
import  './fancybox';

jQuery(function ($) {




	//page
	var $hamburger = $(".hamburger"),
			$site = $("body"),
			$menu = $(".main-navigation"),
			$menuitems = $(".menu-item"),
			$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});
	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}
	$screenOverlay.on('click', closeMenu);

	$(document).bind('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });

	AOS.init({
		easing: 'ease-out-back',
		duration: 1250,
		delay: 250,
		once: false
	});
	
		$('#page-content .wp-block-column,#page-content .wp-block-group,h2,.mailing-list, .wp-block-image').each(function(i) {
			$(this).attr('data-aos', 'fade-up');
		});
	
	AOS.refreshHard(); 



	var all = $('g#Frame > g').get();

	function animate() {

		var elem = all.shift();
		$(elem).animate({
			"opacity": 1
		},150, function () {
			if (all.length > 0)
				window.setTimeout(animate, 10);
		});


	}
	animate();


	 
	$(".pagenav a").each(function() {
		var $this = $(this);       
		var _href = $this.attr("href"); 
		$this.attr("href", _href + '#altnav');
		
	 });


	$('body').on('click', '.ajax-menu .button', function(e){

		var $this = $(this),
			$page = $this.attr('id'),
			$pagina = $this.attr('href'),
			pathname = this.pathname,
			numItems = $('#outer-wrapper').length;

			//alert ($page); 
			// console.log(numItems);
		e.preventDefault();
		$('body').removeClass('page-loaded').addClass('page-loading');
	
		//if ( numItems > 2 ) {
			$( '#outer-wrapper #page-content' ).remove();
		//}

		$('html, body').animate({
			scrollTop: $("#outer-wrapper").offset().top - 50
		}, 2000);
	
		$.get({
			url:$page,
			cache: true
			},function(data) {
			var $items = $(data).find('#page-content');
				// html = $(data).html;
			var parser = new DOMParser();
			doc = parser.parseFromString(data, "text/html");
			// body classes
			var docClass = doc.body.getAttribute('class');
			// Garbage collection, you don't need this anymore.
			parser = doc = null;
	
			// history.pushState({"html":html}, '', $page);
			history.pushState('', '', pathname);
				// windowHeight = $(window).height();
			$items.imagesLoaded().always( function( instance ) {
	
				$items.hide().appendTo('#outer-wrapper').fadeIn(1000);

				
			});
			// $items.fadeIn(500, function() {
			//     $( '.outer-wrapper >.wrapper:not(:last-child)' ).delay(500).queue(function(){
			//         $(this).css('height','100%');
			//         slider.reloadSlider();
			//     });
			// });
		});


	
	});


});
